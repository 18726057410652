
<template>
  <div>
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedUpsellGroup"
      @clear-selected-object="selectedUpsellGroup = null"
    >
      <template
        v-slot:list="{ item }"
      >
        <ActionList
          :key="item.key"
          :filters="[]"
          :load-items-callback="getUpsellGroups"
          :call-to-actions="callToActions"
        >
          <template v-slot:item="{ item, index }">
            <UpsellGroupListItem
              :upsell-group="item"
              :key="item.uuid"
              @selectUpsellGroup="setSelectedUpsellGroup"
              @updateSelectedUpsellGroup="updateSelectedUpsellGroup"
            />
            <v-divider
              inset
              :key="index"
            />
          </template>
        </ActionList>
      </template>
      <template slot="profile">
        <UpsellGroupProfile
          v-if="selectedUpsellGroup"
          :upsell-group="selectedUpsellGroup"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>
export default {
	name: 'UpsellGroups',
	components: {
		'ExtendedView': () => import('@/components/common/ExtendedView'),
		'ActionList': () => import('@/components/common/list/ActionList'),
		'UpsellGroupListItem': () => import('@/components/upsell-group/UpsellGroupListItem'),
        'UpsellGroupProfile': () => import('@/components/upsell-group/UpsellGroupProfile')
	},
	data() {
		return {
			selectedUpsellGroup: null,
			tabs: [
				{
					title: this.$t('all'),
					key: 'all'
				}
			],
			callToActions: [
				{
					title: this.$t('add'),
					callback: this.showUpsellGroupDialog
				}
			]
		}
	},
	methods: {
		getUpsellGroups({limit, skip, conditions}) {
			return this.$store.dispatch('getUpsellGroups', {limit: limit, conditions})
		},
        setSelectedUpsellGroup(upsellGroup) {
            this.selectedUpsellGroup = upsellGroup
        },
		updateSelectedUpsellGroup(template) {
			if(upsellGroup.uuid === this.selectedUpsellGroup.uuid) {
				this.selectedUpsellGroup = upsellGroup
			}
		},
		showUpsellGroupDialog(){
			this.$store.commit('updateDataToMutate', {
				title: this.$t('createUpsellGroup'),
				objectToMutate: {},
				formComponent: 'components/upsell-group/UpsellGroupForm',
				saveCallback: function(newUpsellGroup) {
					this.$store.dispatch('createUpsellGroup', newUpsellGroup).then(res => {
						if(res) {
							this.$store.commit('updateActionSuccess', {
								message: this.$t('success'),
								subMessage: this.$t('successfullyCreatedUpsellGroup')
							})
						} else {
							this.$store.commit('updateActionError', {
								message: this.$t('error'),
								subMessage: this.$t('couldNotCreateUpsellGroup')
							})
						}
					}).catch(() => {
						this.$store.commit('updateActionError', {
							message: this.$t('error'),
							subMessage: this.$t('couldNotCreateUpsellGroup')
						})
					})
				}
			})
		}
	},
	mounted() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
	  },
}
</script>
